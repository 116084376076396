@import 'src/assets/css/helpers/variables.scss';

.cardWrap {
  margin: 0 15px;
  background: #fff;
  box-shadow: 0px 0px 14px 2px rgba(163, 163, 163, 0.3);
  border-radius: 8px;
  overflow: hidden;
  border: 2px solid #dedddd;
  height: 100%;

  .cardImage {
    height: 300px;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .cardDescription {
    padding: 15px;
    display: flex;
    flex-wrap: wrap;

    height: calc(100% - 300px);

    h4 {
      color: #151515;
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      text-align: center;
      flex: 1 100%;

      @media (min-width: 768px) {
        font-size: 18px;
        line-height: 24px;
      }
    }

    h5 {
      color: #151515;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      text-align: center;
      min-height: 40px;
      flex: 1 100%;

      @media (min-width: 768px) {
        font-size: 14px;
        line-height: 20px;
      }

      span {
        font-size: 12px;
        line-height: 18px;

        @media (min-width: 768px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    ul {
      list-style-type: none;
      padding: 0;
      min-height: 70px;
      max-width: 100%;
      margin-bottom: 30px;
      flex: 1 100%;

      li {
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        color: #151515;
        display: flex;
        flex-wrap: wrap;

        &:not(:last-child) {
          margin-bottom: 5px;
        }

        &:before {
          content: "\2713";
          margin-right: 5px;
          width: 12px;
        }

        span {
          flex: 0 0 calc(100% - 17px);
        }
      }
    }

    .desc {
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
      color: #151515;
      display: flex;
      flex-wrap: wrap;
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 15px;
      flex: 1 100%;

      .button {
        max-width: 220px;
      }
    }
  }

  &.horizontalCard {
    display: flex;
    flex-wrap: wrap;
    height: 100%;

    .cardImage {
      max-width: 30%;

      @media (min-width: 1200px) and (max-width: 1600px) {
        max-width: 40%;
      }

      position: relative;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .cardDescription {
      margin-left: 15px;
      max-width: calc(70% - 15px);
      height: auto;

      @media (min-width: 1200px) and (max-width: 1600px) {
        max-width: calc(60% - 15px);
      }

      width: 100%;

      h4,
      h5 {
        text-align: center;
      }

      ul {
        max-width: 100%;
      }

      ul,
      .buttons {
        justify-content: center;
      }
    }
  }
}


.campsModal {
  [class~='modal-dialog'] {
    max-width: 800px;
    width: 100%;

    [class~='modal-header'] {
      [class~='modal-title'] {
        font-size: 28px;
        font-weight: 600;

        span {
          font-size: 16px;
        }
      }

      [class~='btn'] {
        max-width: 180px;
        margin: 20px 0 0;
      }

      [class~='close'] {
        background-color: transparent;
        padding: 0;
        border: 0;
        margin: 0;

        span {
          font-size: 32px;
        }
      }

    }

    [class~='modal-body'] {
      .desc {
        h3 {
          color: $brand;
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 15px;
          text-align: left;
        }

        p {
          color: $black;
          font-size: 14px;

          &:not(:last-child) {
            margin-bottom: 15px;
          }
        }

        ul {
          display: flex;
          flex-wrap: wrap;
          align-items: center;

          li {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            img {
              height: 64px;
              width: auto;
              display: block;
              margin: 0 auto 20px;
            }

            span {
              flex: 0 0 100%;
              text-align: center;
            }
          }

          &[class~="not-inlined"] {
            gap: 5px;
            padding: 0;

            li {
              flex: 0 0 100%;
              justify-content: flex-start;
              color: $black;

              &:before {
                content: '';
                width: 6px;
                height: 6px;
                background-color: $paragraph;
                border-radius: 50px;
                margin-right: 10px;
              }
            }
          }
        }
      }

      .logisticsWrap {
        ul {
          display: grid;
          grid-template-columns: repeat(2, 1fr);

          li {
            padding: 20px;

            &:nth-child(odd) {
              border-right: 2px solid $paragraph;
            }

            &:nth-child(1),
            &:nth-child(2) {
              border-bottom: 2px solid $paragraph;
            }

            .title {
              display: block;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}