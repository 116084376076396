.howToApply {
  .title {
    font-size: 32px;
    line-height: 38px;
    font-weight: 700;
    margin-bottom: 50px;
  }
  .keyDetailsDesc {
    font-size: 16px;
  }
  .featuresDesc {
    font-size: 16px;
    display: flex;
    align-items: flex-start;
    .icon {
      display: flex;
      margin-right: 10px;
      margin-top: 6px;
      background: #005d92;
      background: linear-gradient(to right, #005d92 0%, #38b6ff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .buttonsWrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;
  }
}
