@import "src/assets/css/helpers/variables.scss";

.futureAiLeaders {
  padding: 50px 0;

  .headline {
    font-size: 30px;
    line-height: 40px;
    color: #000000;
    font-family: "Segoe UI Bold";
    position: relative;
    margin: 0 auto 50px;
    text-align: center;

    @media (min-width: 992px) {
      text-align: left;
    }

    @media (min-width: 1200px) {
      font-size: 40px;
      line-height: 50px;
    }

    @media (min-width: 1600px) {
      font-size: 50px;
      line-height: 60px;
    }

    .barOverline {
      font-size: 50px;
      line-height: 60px;
      color: #000000;
      font-family: "seguibl";
      position: relative;
      display: inline-block;

      @media (min-width: 1600px) {
        font-size: 89px;
        line-height: 99px;
      }

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 100%;
        height: 20px;
        background: rgb(0, 93, 146);
        background: radial-gradient(circle,
            rgb(0, 92, 145),
            rgba(56, 182, 255, 0.78));
      }
    }

    .boText {
      position: relative;
      z-index: 2;
    }
  }

  .topDesc {
    text-align: center;

    @media (min-width: 992px) {
      text-align: left;
      font-size: 18px;
      font-weight: 500;
    }
  }

  .tabsNav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 30px;

    li {
      max-width: 300px;
      width: 100%;
    }

    .tabTitle {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 3px solid $brand;
      border-radius: 8px;
      background-color: #fff;
      padding: 15px;
      position: relative;
      overflow: hidden;
      text-align: center;
      min-height: 81px;
      width: 100%;
      transition: $transition;
      span {
        font-size: 16px;
        font-weight: 600;
        text-transform: capitalize;
        position: relative;
        z-index: 2;
        color: $brand;
        transition: $transition;

        span {
          display: block;
          font-size: 14px;
        }
      }

      &:before {
        content: '';
        position: absolute;
        top: -800px;
        left: -850px;
        width: 1000px;
        height: 1000px;
        border-radius: 100%;
        transition: $transition;
        background: linear-gradient(94deg,
            rgba(52, 110, 138, 1) 0%,
            rgba(122, 205, 246, 1) 100%);
      }

      &:hover,
      &:global(.active) {
        color: #fff;

        span {
          color: #fff;
        }

        &:before {
          top: -650px;
          left: -400px;
          border-radius: 0;
        }
      }
      &:global(.active) {
        border-color: $teal;
      }
    }
  }

  .cardTitle {
    text-align: center;
  }

  .main {
    box-shadow: 0px 3px 49px #0000001a;
    padding: 35px;
    border-radius: 21px;
    margin: 30px auto;
    display: flex;
    flex-wrap: wrap;

    @media (min-width: 768px) {
      min-height: 505px;
    }

    .tagline {
      h3 {
        margin: 0 auto 10px;
        color: #000000;
        font-family: "Segoe UI Bold" !important;

        @media (min-width: 992px) {
          font-size: 20px;
          line-height: 30px;
        }

        @media (min-width: 1200px) {
          font-size: 26px;
          line-height: 36px;
          min-height: 72px;
        }
      }
    }

    .desc {
      @media (min-width: 768px) {
        min-height: 72px;
      }

      p {
        font-size: 18px;
        line-height: 24px;
        color: #343a40;

        a {
          font-weight: 700;
          color: #000;
          transition: 0.3s ease all;

          &:hover {
            color: #333;
          }
        }
      }
    }

    .listCheck {
      padding-left: 10px;

      li {
        color: #fff;
        list-style-type: none;

        &:before {
          content: "\2713";
          margin-right: 10px;
        }
      }
    }

    .buttonsArea {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: row wrap;
      gap: 0 15px;
      flex: 0 0 100%;

      .button {
        max-width: 200px;
      }
    }
  }

  .box {
    border: 1px solid rgba(200, 200, 200, 0.51);
    border-radius: 10px;
    padding: 30px;
    max-width: 46%;
    margin: 15px auto;

    h4 {
      font-size: 21px;
      line-height: 28px;
      color: #000000;
      font-family: "seguibl";
      text-transform: uppercase;

      span {
        color: #0ca9a5;
      }
    }
  }

  .bottomDesc {
    font-size: 16px;
  }
}